import React, { useState, useRef, useEffect, Component } from "react";
import {
  DKSpinner,
  DKIcon,
  DKInput,
  DKIcons,
  DKSearchBar,
} from "deskera-ui-library";
// import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import userAvatarLight from "../../assets/chatbot/user-avatar_light.svg";
import userAvatarDark from "../../assets/chatbot/user-avatar_dark.svg";
import aiAvatar from "../../assets/chatbot/ai-avatar.svg";
import MarkdownIt from "markdown-it";
import renderGraph from "../../utils/GraphUtils";
import { render } from "@testing-library/react";
import SpreadsheetPreview from "./SpreadsheetPreview";
// import sample_spreadsheet from '../../assets/chatbot/example_xlsx_file.xlsx'
import sample_spreadsheet from "../../assets/chatbot/example_csv_file.csv";
import TimelineLoader from "./TimelineLoader";
import { FinalResultsLoader } from "./FinalResultLoader";
import { AnimatedText } from "./AnimatedText";
export default function ChatMessage({ message, darkMode }) {
  // NOTE:: message.message is the answer string
  // message.message is string
  // message.message.graphContent
  let markdownMessage = null;

  function isHtml(str) {
    return /<[a-z][\s\S]*>/i.test(str);
  }

  if (
    message &&
    message?.sender === "bot" &&
    typeof message?.content?.text_content === "string"
  ) {
    if (!isHtml(message?.content?.text_content)) {
      markdownMessage = new MarkdownIt().render(message?.content?.text_content);
    } else {
      markdownMessage = null;
    }
  }
  let renderedGraph = null;
  if (
    message &&
    message.message &&
    message.message.graphContent &&
    message.message.graphContent.graph_vals &&
    Array.isArray(message.message.graphContent.graph_vals)
  ) {
    renderedGraph = renderGraph({
      graph_vals: message.message.graphContent.graph_vals,
      type: message.message.graphContent.type,
    });
  }
  const [showFinalResults, setShowFinalResults] = useState(message.isReply);

  return (
    <>
      {message?.sender === "user" ? (
        <div className={`user-message-container${darkMode ? "-dark" : ""}`}>
          <div className="user-avatar">
            <img
              src={darkMode ? userAvatarLight : userAvatarDark}
              alt="User Avatar"
            />
          </div>
          <div className="user-message ml-l w-80">
            <div
              className={`user-message-intro${
                darkMode ? "-dark" : ""
              } border-radius-l p-l`}
            >
              {message?.content.text_content}
            </div>
          </div>
        </div>
      ) : (
        <div className={`bot-message-container${darkMode ? "-dark" : ""}`}>
          <div className="bot-avatar">
            <img src={aiAvatar} alt="AI Avatar" />
          </div>
          <div className="bot-message ml-l w-80">
            <div
              className={`bot-message-intro${
                darkMode ? "-dark" : ""
              } border-radius-l p-l`}
            >
              {message?.content.text_content.startsWith("Loading") ? (
                <div className="timeline-container">
                  <div className="timeline-line"></div>
                  <TimelineLoader
                    steps={[
                      { label: "Processing request", duration: 2000 },
                      { label: "Thinking", duration: 6000 },
                      { label: "Determining which data to crawl", duration: 6000 },
                      { label: "Analyzing data patterns", duration: 8000 },
                      { label: "Identifying relevant datasets", duration: 8000 },
                      { label: "Extracting key information", duration: 10000 },
                      { label: "Validating data integrity", duration: 7000 },
                      { label: "Normalizing data formats", duration: 5000 },
                      { label: "Aggregating data points", duration: 9000 },
                      { label: "Cross-referencing sources", duration: 11000 },
                      { label: "Filtering results", duration: 20000 },
                      { label: "Compiling insights", duration: 12000 },
                      { label: "Optimizing output", duration: 16000 },
                      { label: "Generating report", duration: 120000 },
                      { label: "Verifying accuracy", duration: 15000 }
                    ]}
                    AnimatedTextComponent={AnimatedText}

                  />
                </div>
              ) : (
                <>
                  {message.isReply && showFinalResults && (
                    <FinalResultsLoader onComplete={() => setShowFinalResults(false)} AnimatedTextComponent={AnimatedText}
                    />
                  )}
                  {(!message.isReply || !showFinalResults) && (
                    <>
                      {markdownMessage ? (
                        <div
                          className={`markdown-msg`}
                          dangerouslySetInnerHTML={{ __html: markdownMessage }}
                        />
                      ) : (
                        <div
                          className="markdown-msg"
                          dangerouslySetInnerHTML={{
                            __html: message?.content.text_content,
                          }}
                        />
                      )}
                      {message?.content?.attachments && (
                        <SpreadsheetPreview
                          darkMode={darkMode}
                          spreadsheet={message?.content?.attachments[0]?.fileUrl}
                        />
                      )}
                      {message?.content?.steps_text && (
                        <div className="steps-section">
                          <h4>Steps I took to find the answer</h4>
                          <div
                            className="markdown-msg"
                            dangerouslySetInnerHTML={{
                              __html: message.content.steps_text,
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {message && message.message && message.message.graphContent && (
              <div
                className={`bot-message-graph-insight-container${
                  darkMode ? "-dark" : ""
                }`}
              >
                <div
                  className={`bot-message-graph${
                    darkMode ? "-dark" : ""
                  } border-radius-l  p-l custom-scroll`}
                >
                  {renderedGraph
                    ? renderedGraph
                    : message.message.graphContent.graph_vals}
                </div>
                <div
                  className={`bot-message-insight${
                    darkMode ? "-dark" : ""
                  } border-radius-l  p-l`}
                >
                  {message.message.graphContent.insight}
                </div>
              </div>
            )}
            {message &&
              message.message &&
              message.message.graphContent &&
              message.message.graphContent.outro && (
                <div
                  className={`bot-message-outro${
                    darkMode ? "-dark" : ""
                  } border-radius-l  p-l`}
                >
                  {message.message.graphContent.outro}
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
}
