import { useEffect, useState, useRef } from 'react';
// import chats from '../../assets/chats';
import backArrowDark from "../../assets/chatbot/back-arrow_dark.svg";
import backArrowLight from "../../assets/chatbot/back-arrow_light.svg";
import ChatSidebarFooter from './ChatSidebarFooter';
import messageDarkBlue from '../../assets/chatbot/message_dark_blue.svg';
import messageLightBlue from '../../assets/chatbot/message_light_blue.svg';
import messageWhite from '../../assets/chatbot/message_white.svg';
import messageGray from '../../assets/chatbot/message_gray.svg';

import { deleteThread, getMessageList, renameThread } from '../../utils/ChatUtils';
import DeleteThreadPopup from './DeleteThreadPopup';

export default function ChatSidebar({ setSuggestion, loading, selectedThread, setSelectedThread, setChatLog, threads, darkMode, pageWidth, sidebarIsActive, setSidebarIsActive, setThreads }) {
  const [openModal, setOpenModal] = useState({ isOpen: false, threadId: null });
  const [renameThreadId, setRenameThreadId] = useState(null);
  const [renameThreadString, setRenameString] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, threadId: null });
  const inputRef = useRef(null);
  const modalRef = useRef(null);


  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setRenameThreadId(null);
        setRenameString(null);
      }
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenModal({ isOpen: false, threadId: null });
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (renameThreadId && inputRef.current) {
      inputRef.current.focus();
    }
  }, [renameThreadId]);

  async function handleGetMessages(thread) {
    if(!loading) {
      setOpenModal({ isOpen: false, threadId: null });
      setSelectedThread(thread);
      async function handleGetMessageList() {
        const messageList = await getMessageList(thread?._id);
        setChatLog(messageList);
      }
      handleGetMessageList()
    }
  }

  const toggleModal = (threadId, e) => {
    e.stopPropagation();
    if(!loading) {
      setOpenModal(prev => ({
        isOpen: prev.threadId !== threadId || !prev.isOpen,
        threadId: threadId
      }));
    }
  };

  const handleDelete = (e, threadId) => {
    e.stopPropagation();
    if(!loading) {
      setDeleteConfirmation({ isOpen: true, threadId: threadId });
      setOpenModal({ isOpen: false, threadId: null });
    }
  };

  const confirmDelete = async () => {
    if (deleteConfirmation.threadId) {
      await deleteThread(deleteConfirmation.threadId);
      setThreads(prevThreads => prevThreads.filter(thread => thread?._id !== deleteConfirmation.threadId));
      if(selectedThread?._id === deleteConfirmation.threadId) {
        setSelectedThread(null);
        setChatLog([]);
      }
      setDeleteConfirmation({ isOpen: false, threadId: null });
    }
  };

  const handleRenameClick = async (e, threadId) => {
    e.stopPropagation();
    if(!loading) {
      const threadToBeRenamed = threads.find((thread) => thread._id === threadId);
      setRenameThreadId(threadId);
      setRenameString(threadToBeRenamed?.name?.replace(/"/g, ''));
      setOpenModal({ isOpen: false, threadId: null });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenModal({ isOpen: false, threadId: null });
    setThreads(prevThreads => prevThreads.map(thread => 
      thread?._id === renameThreadId ? { ...thread, name: renameThreadString } : thread
    ));
    if(selectedThread?._id === renameThreadId) {
      setSelectedThread(prevSelected => 
        prevSelected && prevSelected?._id === renameThreadId 
          ? { ...prevSelected, name: renameThreadString } 
          : prevSelected
      );
    }
    await renameThread(renameThreadString, renameThreadId);
    setRenameThreadId(null);
    setRenameString(null);
  }

  const categorizeThreads = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return threads.reduce((acc, thread) => {
      const createdAt = new Date(thread.created_at);
      if (createdAt.toDateString() === today.toDateString()) {
        acc.today.push(thread);
      } else if (createdAt.toDateString() === yesterday.toDateString()) {
        acc.yesterday.push(thread);
      } else {
        acc.older.push(thread);
      }
      return acc;
    }, { today: [], yesterday: [], older: [] });
  }

  const renderThreads = () => {
    const categorizedThreads = categorizeThreads(threads);
  
    const renderThreadGroup = (group, title) => (
      <div key={title} style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <span style={{ position: 'relative', paddingLeft: '15px', paddingTop: '4px', paddingBottom: '4px', color: darkMode ? '#bcbbbb' : '#002E74', letterSpacing: '1.2px', fontSize: '12px' }}>{title}</span>
        {group.map((thread) => (
          <div key={thread?._id} className={`chat-sidebar-item${darkMode ? '-dark' : ''}${thread?._id===selectedThread?._id ? '-selected' : ''} d-flex pl-l align-items-center ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => handleGetMessages(thread)}>
            <span className='chat-icon'>
              {darkMode
                ? thread?._id===selectedThread?._id ? <img src={messageWhite} alt="message-icon" /> : <img src={messageGray} alt="message-icon" />
                : thread?._id===selectedThread?._id ? <img src={messageDarkBlue} alt="message-icon" /> : <img src={messageLightBlue} alt="message-icon" />
              }
            </span>
            <span className='chat-name cursor-default ellipsis' style={{ width: `190px`}}>
              {renameThreadId && renameThreadId === thread?._id ? 
              <form onSubmit={(e) => handleSubmit(e)}>
                <input
                ref={inputRef}
                  type="text"
                  id="name"
                  name="name"
                  value={renameThreadString}
                  onChange={(e) => setRenameString(e.target.value)}
                  onKeyDown={(e) => {
                    if(e.key === 'Escape') {
                      setRenameString('');
                      setRenameThreadId(null);
                    } 
                  }}
                  onBlur={() => {
                    setRenameThreadId(null);
                    setRenameString(null);
                  }}
                  style={{
                    border: 'none',
                    appearance: 'none',
                    outline: 'none',
                  }}
                />
              </form> : 
              thread && thread?.name && thread?.name?.replace(/"/g, '')}
            </span>
            <button onClick={(e) => toggleModal(thread?._id, e)} className={`sidebar-three-dots${darkMode ? '-dark' : ''}${thread?._id===selectedThread?._id ? '-selected' : ''} ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}`}>•••</button>
            {openModal.isOpen && openModal.threadId === thread?._id && (
              <div ref={modalRef} style={{position: 'absolute', right: '8px', top: '80%', display: 'flex', flexDirection: 'column', backgroundColor: `${darkMode ? '#5C5C5C' : '#E6E6E6'}`, zIndex: '1', width: '100px', borderRadius: '10px', padding: '5px', border: `${darkMode ? '1px solid #323232' : '1px solid #bcbbbb'}`}}>
                <button 
                  onClick={(e) => handleDelete(e, thread?._id)}
                  className={`chat-sidebar-modal-btn${darkMode ? '-dark' : ''}`}
                >Delete</button>
                <button 
                  onClick={(e) => handleRenameClick(e, thread?._id)}
                  className={`chat-sidebar-modal-btn${darkMode ? '-dark' : ''}`}
                >Rename</button>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  
    return (
      <>
        {categorizedThreads.today.length > 0 && renderThreadGroup(categorizedThreads.today, "TODAY")}
        {categorizedThreads.yesterday.length > 0 && renderThreadGroup(categorizedThreads.yesterday, "YESTERDAY")}
        {categorizedThreads.older.length > 0 && renderThreadGroup(categorizedThreads.older, "OLDER")}
      </>
    );
  };

  return (
    <div
      className={`chat-sidebar${darkMode ? '-dark' : ''} ${sidebarIsActive ? 'active-sidebar' : 'inactive-sidebar'} d-flex column h-70`} 
    >
      <div className='chat-sidebar-header ml-xl fs-xl fw-b pb-l'>Deskera <span className='fw-r'>AI</span></div>
      <img className={`chat-sidebar-back-btn${darkMode ? '-dark' : ''} cursor-pointer`} onClick={() => setSidebarIsActive(false)} src={darkMode ? backArrowLight : backArrowDark} alt="back-arrow" />
      <div className={`chat-sidebar-header-separator${darkMode ? '-dark' : ''} w-100`}></div>
        
      {/* <small className={`chat-date${darkMode ? '-dark' : ''} ml-l mt-l`} style={{color: '#9D9D9D', fontWeight: '400'}}>TODAY</small> */}
      <div className={`chat-sidebar-list${darkMode ? '-dark' : ''} w-100 pt-m custom-scroll`} >
        { threads && renderThreads()}
      </div>
      <ChatSidebarFooter loading={loading} setSelectedThread={setSelectedThread} setChatLog={setChatLog} darkMode={darkMode} />
      {deleteConfirmation.isOpen && <DeleteThreadPopup darkMode={darkMode} confirmDelete={confirmDelete} setDeleteConfirmation={setDeleteConfirmation} />}
    </div>
  )
}
